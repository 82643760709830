import { useContext } from 'react';
import { GlobalContext } from '../../components/data/GlobalContext';




function RedesContent(props) {
    const dbData = useContext(GlobalContext);
    return (
        <>

            <h1 className='text-center'>Follow Us</h1>
            <div
                className='contentRedes'

            >
                {
                    dbData.dbSocialMedia.directorios && dbData.dbSocialMedia.directorios[0].name !== ' ' ?
                        dbData.dbSocialMedia.directorios.map((items, index) => {
                            return (
                                <div key={index}>
                                    <a href={items.url} target='_blank'>
                                        <img src={`${items.image}`} alt={items.name} />
                                    </a>
                                </div>
                            )
                        }) : null
                }
                {
                    dbData.dbSocialMedia.redes && dbData.dbSocialMedia.redes[0].name !== ' ' ?
                        dbData.dbSocialMedia.redes.map((items, index) => {
                            return (
                                <div key={index}>
                                    <a href={items.url} target='_blank'>
                                        <img src={`${items.image}`} alt={items.name} />
                                    </a>
                                </div>
                            )
                        }) : null
                }
            </div>
        </>
    );
}

export default RedesContent;